// @autogenerated


export default [
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport H1 from \"@govtnz/ds/build/mustache/H1.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport H3 from \"@govtnz/ds/build/mustache/H3.mustache\";\nimport H4 from \"@govtnz/ds/build/mustache/H4.mustache\";\nimport H5 from \"@govtnz/ds/build/mustache/H5.mustache\";\nimport H6 from \"@govtnz/ds/build/mustache/H6.mustache\";\n\n// The variables H1, H2, H3, H4, H5, H6 are all strings that are mustache templates.\n\nexport default `${Mustache.render(H1, {\n  \"styleSize=xlarge\": true,\n  styleSize: true,\n  children: `g-heading-xl`\n})}${Mustache.render(H2, {\n  \"styleSize=large\": true,\n  styleSize: true,\n  children: `g-heading-l`\n})}${Mustache.render(H3, {\n  \"styleSize=medium\": true,\n  styleSize: true,\n  children: `g-heading-m`\n})}${Mustache.render(H4, {\n  \"styleSize=small\": true,\n  styleSize: true,\n  children: `g-heading-s`\n})}${Mustache.render(H5, {\n  \"styleSize=xsmall\": true,\n  styleSize: true,\n  children: `g-heading-xs`\n})}${Mustache.render(H6, {\n  \"styleSize=xxsmall\": true,\n  styleSize: true,\n  children: `g-heading-xxs`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-js/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport H3 from \"@govtnz/ds/build/react-js/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\nimport H4 from \"@govtnz/ds/build/react-js/H4\";\nimport \"@govtnz/ds/build/css/H4.css\"; // or @govtnz/ds/build/scss/H4.scss\nimport H5 from \"@govtnz/ds/build/react-js/H5\";\nimport \"@govtnz/ds/build/css/H5.css\"; // or @govtnz/ds/build/scss/H5.scss\nimport H6 from \"@govtnz/ds/build/react-js/H6\";\nimport \"@govtnz/ds/build/css/H6.css\"; // or @govtnz/ds/build/scss/H6.scss\n\nexport default () => (\n  <React.Fragment>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n    <H4 styleSize=\"small\">g-heading-s</H4>\n    <H5 styleSize=\"xsmall\">g-heading-xs</H5>\n    <H6 styleSize=\"xxsmall\">g-heading-xxs</H6>\n  </React.Fragment>\n);\n",
    "react-ts": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-ts/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport H3 from \"@govtnz/ds/build/react-ts/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\nimport H4 from \"@govtnz/ds/build/react-ts/H4\";\nimport \"@govtnz/ds/build/css/H4.css\"; // or @govtnz/ds/build/scss/H4.scss\nimport H5 from \"@govtnz/ds/build/react-ts/H5\";\nimport \"@govtnz/ds/build/css/H5.css\"; // or @govtnz/ds/build/scss/H5.scss\nimport H6 from \"@govtnz/ds/build/react-ts/H6\";\nimport \"@govtnz/ds/build/css/H6.css\"; // or @govtnz/ds/build/scss/H6.scss\n\nexport default () => (\n  <React.Fragment>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n    <H4 styleSize=\"small\">g-heading-s</H4>\n    <H5 styleSize=\"xsmall\">g-heading-xs</H5>\n    <H6 styleSize=\"xxsmall\">g-heading-xxs</H6>\n  </React.Fragment>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-js-styled-components/H1\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport H3 from \"@govtnz/ds/build/react-js-styled-components/H3\";\nimport H4 from \"@govtnz/ds/build/react-js-styled-components/H4\";\nimport H5 from \"@govtnz/ds/build/react-js-styled-components/H5\";\nimport H6 from \"@govtnz/ds/build/react-js-styled-components/H6\";\n\nexport default () => (\n  <React.Fragment>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n    <H4 styleSize=\"small\">g-heading-s</H4>\n    <H5 styleSize=\"xsmall\">g-heading-xs</H5>\n    <H6 styleSize=\"xxsmall\">g-heading-xxs</H6>\n  </React.Fragment>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-ts-styled-components/H1\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport H3 from \"@govtnz/ds/build/react-ts-styled-components/H3\";\nimport H4 from \"@govtnz/ds/build/react-ts-styled-components/H4\";\nimport H5 from \"@govtnz/ds/build/react-ts-styled-components/H5\";\nimport H6 from \"@govtnz/ds/build/react-ts-styled-components/H6\";\n\nexport default () => (\n  <React.Fragment>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n    <H4 styleSize=\"small\">g-heading-s</H4>\n    <H5 styleSize=\"xsmall\">g-heading-xs</H5>\n    <H6 styleSize=\"xxsmall\">g-heading-xxs</H6>\n  </React.Fragment>\n);\n",
    "vue-js": "<template>\n  <c-h1 styleSize=\"xlarge\">\n    g-heading-xl\n  </c-h1>\n  <c-h2 styleSize=\"large\">\n    g-heading-l\n  </c-h2>\n  <c-h3 styleSize=\"medium\">\n    g-heading-m\n  </c-h3>\n  <c-h4 styleSize=\"small\">\n    g-heading-s\n  </c-h4>\n  <c-h5 styleSize=\"xsmall\">\n    g-heading-xs\n  </c-h5>\n  <c-h6 styleSize=\"xxsmall\">\n    g-heading-xxs\n  </c-h6>\n</template>\n<script>\nimport Vue from \"vue\";\nimport H1 from \"@govtnz/ds/build/vue-js/H1.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport H3 from \"@govtnz/ds/build/vue-js/H3.vue\";\nimport H4 from \"@govtnz/ds/build/vue-js/H4.vue\";\nimport H5 from \"@govtnz/ds/build/vue-js/H5.vue\";\nimport H6 from \"@govtnz/ds/build/vue-js/H6.vue\";\n\nexport default {\n  components: {\n    \"c-h1\": H1,\n    \"c-h2\": H2,\n    \"c-h3\": H3,\n    \"c-h4\": H4,\n    \"c-h5\": H5,\n    \"c-h6\": H6\n  }\n};\n</script>\n",
    "vue-ts": "<template>\n  <c-h1 styleSize=\"xlarge\">\n    g-heading-xl\n  </c-h1>\n  <c-h2 styleSize=\"large\">\n    g-heading-l\n  </c-h2>\n  <c-h3 styleSize=\"medium\">\n    g-heading-m\n  </c-h3>\n  <c-h4 styleSize=\"small\">\n    g-heading-s\n  </c-h4>\n  <c-h5 styleSize=\"xsmall\">\n    g-heading-xs\n  </c-h5>\n  <c-h6 styleSize=\"xxsmall\">\n    g-heading-xxs\n  </c-h6>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport H1 from \"@govtnz/ds/build/vue-ts/H1.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport H3 from \"@govtnz/ds/build/vue-ts/H3.vue\";\nimport H4 from \"@govtnz/ds/build/vue-ts/H4.vue\";\nimport H5 from \"@govtnz/ds/build/vue-ts/H5.vue\";\nimport H6 from \"@govtnz/ds/build/vue-ts/H6.vue\";\n\nexport default {\n  components: {\n    \"c-h1\": H1,\n    \"c-h2\": H2,\n    \"c-h3\": H3,\n    \"c-h4\": H4,\n    \"c-h5\": H5,\n    \"c-h6\": H6\n  }\n};\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: H1.css, H2.css, H3.css, H4.css, H5.css, H6.css\nOR in Sass (SCSS): H1.scss, H2.scss, H3.scss, H4.scss, H5.scss, H6.scss\n-->\n<h1 class=\"g-heading g-heading-xl\">g-heading-xl</h1>\n<h2 class=\"g-heading g-heading-l\">g-heading-l</h2>\n<h3 class=\"g-heading g-heading-m\">g-heading-m</h3>\n<h4 class=\"g-heading g-heading-s\">g-heading-s</h4>\n<h5 class=\"g-heading g-heading-xs\">g-heading-xs</h5>\n<h6 class=\"g-heading g-heading-xxs\">g-heading-xxs</h6>\n",
    "css": "",
    "twig-embed": "{% embed \"H1.html.twig\"  with {'styleSize':'xlarge', 'children':'g-heading-xl'} only %}{% endembed %}{% embed \"H2.html.twig\"  with {'styleSize':'large', 'children':'g-heading-l'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'medium', 'children':'g-heading-m'} only %}{% endembed %}{% embed \"H4.html.twig\"  with {'styleSize':'small', 'children':'g-heading-s'} only %}{% endembed %}{% embed \"H5.html.twig\"  with {'styleSize':'xsmall', 'children':'g-heading-xs'} only %}{% endembed %}{% embed \"H6.html.twig\"  with {'styleSize':'xxsmall', 'children':'g-heading-xxs'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport H3 from \"@govtnz/ds/build/mustache/H3.mustache\";\n\n// The variables H3 are all strings that are mustache templates.\n\nexport default `${Mustache.render(H3, {\n  \"styleSize=xlarge\": true,\n  styleSize: true,\n  children: `H3 g-heading-xl`\n})}${Mustache.render(H3, {\n  \"styleSize=large\": true,\n  styleSize: true,\n  children: `H3 g-heading-l`\n})}${Mustache.render(H3, {\n  \"styleSize=medium\": true,\n  styleSize: true,\n  children: `H3 g-heading-m`\n})}${Mustache.render(H3, {\n  \"styleSize=small\": true,\n  styleSize: true,\n  children: `H3 g-heading-s`\n})}${Mustache.render(H3, {\n  \"styleSize=xsmall\": true,\n  styleSize: true,\n  children: `H3 g-heading-xs`\n})}${Mustache.render(H3, {\n  \"styleSize=xxsmall\": true,\n  styleSize: true,\n  children: `H3 g-heading-xxs`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport H3 from \"@govtnz/ds/build/react-js/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\n\nexport default () => (\n  <React.Fragment>\n    <H3 styleSize=\"xlarge\">H3 g-heading-xl</H3>\n    <H3 styleSize=\"large\">H3 g-heading-l</H3>\n    <H3 styleSize=\"medium\">H3 g-heading-m</H3>\n    <H3 styleSize=\"small\">H3 g-heading-s</H3>\n    <H3 styleSize=\"xsmall\">H3 g-heading-xs</H3>\n    <H3 styleSize=\"xxsmall\">H3 g-heading-xxs</H3>\n  </React.Fragment>\n);\n",
    "react-ts": "import React from \"react\";\nimport H3 from \"@govtnz/ds/build/react-ts/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\n\nexport default () => (\n  <React.Fragment>\n    <H3 styleSize=\"xlarge\">H3 g-heading-xl</H3>\n    <H3 styleSize=\"large\">H3 g-heading-l</H3>\n    <H3 styleSize=\"medium\">H3 g-heading-m</H3>\n    <H3 styleSize=\"small\">H3 g-heading-s</H3>\n    <H3 styleSize=\"xsmall\">H3 g-heading-xs</H3>\n    <H3 styleSize=\"xxsmall\">H3 g-heading-xxs</H3>\n  </React.Fragment>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport H3 from \"@govtnz/ds/build/react-js-styled-components/H3\";\n\nexport default () => (\n  <React.Fragment>\n    <H3 styleSize=\"xlarge\">H3 g-heading-xl</H3>\n    <H3 styleSize=\"large\">H3 g-heading-l</H3>\n    <H3 styleSize=\"medium\">H3 g-heading-m</H3>\n    <H3 styleSize=\"small\">H3 g-heading-s</H3>\n    <H3 styleSize=\"xsmall\">H3 g-heading-xs</H3>\n    <H3 styleSize=\"xxsmall\">H3 g-heading-xxs</H3>\n  </React.Fragment>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport H3 from \"@govtnz/ds/build/react-ts-styled-components/H3\";\n\nexport default () => (\n  <React.Fragment>\n    <H3 styleSize=\"xlarge\">H3 g-heading-xl</H3>\n    <H3 styleSize=\"large\">H3 g-heading-l</H3>\n    <H3 styleSize=\"medium\">H3 g-heading-m</H3>\n    <H3 styleSize=\"small\">H3 g-heading-s</H3>\n    <H3 styleSize=\"xsmall\">H3 g-heading-xs</H3>\n    <H3 styleSize=\"xxsmall\">H3 g-heading-xxs</H3>\n  </React.Fragment>\n);\n",
    "vue-js": "<template>\n  <c-h3 styleSize=\"xlarge\">\n    H3 g-heading-xl\n  </c-h3>\n  <c-h3 styleSize=\"large\">\n    H3 g-heading-l\n  </c-h3>\n  <c-h3 styleSize=\"medium\">\n    H3 g-heading-m\n  </c-h3>\n  <c-h3 styleSize=\"small\">\n    H3 g-heading-s\n  </c-h3>\n  <c-h3 styleSize=\"xsmall\">\n    H3 g-heading-xs\n  </c-h3>\n  <c-h3 styleSize=\"xxsmall\">\n    H3 g-heading-xxs\n  </c-h3>\n</template>\n<script>\nimport Vue from \"vue\";\nimport H3 from \"@govtnz/ds/build/vue-js/H3.vue\";\n\nexport default { components: { \"c-h3\": H3 } };\n</script>\n",
    "vue-ts": "<template>\n  <c-h3 styleSize=\"xlarge\">\n    H3 g-heading-xl\n  </c-h3>\n  <c-h3 styleSize=\"large\">\n    H3 g-heading-l\n  </c-h3>\n  <c-h3 styleSize=\"medium\">\n    H3 g-heading-m\n  </c-h3>\n  <c-h3 styleSize=\"small\">\n    H3 g-heading-s\n  </c-h3>\n  <c-h3 styleSize=\"xsmall\">\n    H3 g-heading-xs\n  </c-h3>\n  <c-h3 styleSize=\"xxsmall\">\n    H3 g-heading-xxs\n  </c-h3>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport H3 from \"@govtnz/ds/build/vue-ts/H3.vue\";\n\nexport default { components: { \"c-h3\": H3 } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: H3.css\nOR in Sass (SCSS): H3.scss\n-->\n<h3 class=\"g-heading g-heading-xl\">H3 g-heading-xl</h3>\n<h3 class=\"g-heading g-heading-l\">H3 g-heading-l</h3>\n<h3 class=\"g-heading g-heading-m\">H3 g-heading-m</h3>\n<h3 class=\"g-heading g-heading-s\">H3 g-heading-s</h3>\n<h3 class=\"g-heading g-heading-xs\">H3 g-heading-xs</h3>\n<h3 class=\"g-heading g-heading-xxs\">H3 g-heading-xxs</h3>\n",
    "css": "",
    "twig-embed": "{% embed \"H3.html.twig\"  with {'styleSize':'xlarge', 'children':'H3 g-heading-xl'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'large', 'children':'H3 g-heading-l'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'medium', 'children':'H3 g-heading-m'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'small', 'children':'H3 g-heading-s'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'xsmall', 'children':'H3 g-heading-xs'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'xxsmall', 'children':'H3 g-heading-xxs'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport CaptionXl from \"@govtnz/ds/build/mustache/CaptionXl.mustache\";\nimport H1 from \"@govtnz/ds/build/mustache/H1.mustache\";\nimport CaptionL from \"@govtnz/ds/build/mustache/CaptionL.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport CaptionM from \"@govtnz/ds/build/mustache/CaptionM.mustache\";\nimport H3 from \"@govtnz/ds/build/mustache/H3.mustache\";\n\n// The variables CaptionXl, H1, CaptionL, H2, CaptionM, H3 are all strings that are mustache templates.\n\nexport default `${Mustache.render(CaptionXl, {\n  children: `caption-xl`\n})}${Mustache.render(H1, {\n  \"styleSize=xlarge\": true,\n  styleSize: true,\n  children: `g-heading-xl`\n})}${Mustache.render(CaptionL, { children: `caption-l` })}${Mustache.render(\n  H2,\n  { \"styleSize=large\": true, styleSize: true, children: `g-heading-l` }\n)}${Mustache.render(CaptionM, { children: `caption-m` })}${Mustache.render(H3, {\n  \"styleSize=medium\": true,\n  styleSize: true,\n  children: `g-heading-m`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport CaptionXl from \"@govtnz/ds/build/react-js/CaptionXl\";\nimport \"@govtnz/ds/build/css/CaptionXl.css\"; // or @govtnz/ds/build/scss/CaptionXl.scss\nimport H1 from \"@govtnz/ds/build/react-js/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport CaptionL from \"@govtnz/ds/build/react-js/CaptionL\";\nimport \"@govtnz/ds/build/css/CaptionL.css\"; // or @govtnz/ds/build/scss/CaptionL.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport CaptionM from \"@govtnz/ds/build/react-js/CaptionM\";\nimport \"@govtnz/ds/build/css/CaptionM.css\"; // or @govtnz/ds/build/scss/CaptionM.scss\nimport H3 from \"@govtnz/ds/build/react-js/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\n\nexport default () => (\n  <React.Fragment>\n    <CaptionXl>caption-xl</CaptionXl>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <CaptionL>caption-l</CaptionL>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <CaptionM>caption-m</CaptionM>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n  </React.Fragment>\n);\n",
    "react-ts": "import React from \"react\";\nimport CaptionXl from \"@govtnz/ds/build/react-ts/CaptionXl\";\nimport \"@govtnz/ds/build/css/CaptionXl.css\"; // or @govtnz/ds/build/scss/CaptionXl.scss\nimport H1 from \"@govtnz/ds/build/react-ts/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport CaptionL from \"@govtnz/ds/build/react-ts/CaptionL\";\nimport \"@govtnz/ds/build/css/CaptionL.css\"; // or @govtnz/ds/build/scss/CaptionL.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport CaptionM from \"@govtnz/ds/build/react-ts/CaptionM\";\nimport \"@govtnz/ds/build/css/CaptionM.css\"; // or @govtnz/ds/build/scss/CaptionM.scss\nimport H3 from \"@govtnz/ds/build/react-ts/H3\";\nimport \"@govtnz/ds/build/css/H3.css\"; // or @govtnz/ds/build/scss/H3.scss\n\nexport default () => (\n  <React.Fragment>\n    <CaptionXl>caption-xl</CaptionXl>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <CaptionL>caption-l</CaptionL>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <CaptionM>caption-m</CaptionM>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n  </React.Fragment>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport CaptionXl from \"@govtnz/ds/build/react-js-styled-components/CaptionXl\";\nimport H1 from \"@govtnz/ds/build/react-js-styled-components/H1\";\nimport CaptionL from \"@govtnz/ds/build/react-js-styled-components/CaptionL\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport CaptionM from \"@govtnz/ds/build/react-js-styled-components/CaptionM\";\nimport H3 from \"@govtnz/ds/build/react-js-styled-components/H3\";\n\nexport default () => (\n  <React.Fragment>\n    <CaptionXl>caption-xl</CaptionXl>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <CaptionL>caption-l</CaptionL>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <CaptionM>caption-m</CaptionM>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n  </React.Fragment>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport CaptionXl from \"@govtnz/ds/build/react-ts-styled-components/CaptionXl\";\nimport H1 from \"@govtnz/ds/build/react-ts-styled-components/H1\";\nimport CaptionL from \"@govtnz/ds/build/react-ts-styled-components/CaptionL\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport CaptionM from \"@govtnz/ds/build/react-ts-styled-components/CaptionM\";\nimport H3 from \"@govtnz/ds/build/react-ts-styled-components/H3\";\n\nexport default () => (\n  <React.Fragment>\n    <CaptionXl>caption-xl</CaptionXl>\n    <H1 styleSize=\"xlarge\">g-heading-xl</H1>\n    <CaptionL>caption-l</CaptionL>\n    <H2 styleSize=\"large\">g-heading-l</H2>\n    <CaptionM>caption-m</CaptionM>\n    <H3 styleSize=\"medium\">g-heading-m</H3>\n  </React.Fragment>\n);\n",
    "vue-js": "<template>\n  <caption-xl>\n    caption-xl\n  </caption-xl>\n  <c-h1 styleSize=\"xlarge\">\n    g-heading-xl\n  </c-h1>\n  <caption-l>\n    caption-l\n  </caption-l>\n  <c-h2 styleSize=\"large\">\n    g-heading-l\n  </c-h2>\n  <caption-m>\n    caption-m\n  </caption-m>\n  <c-h3 styleSize=\"medium\">\n    g-heading-m\n  </c-h3>\n</template>\n<script>\nimport Vue from \"vue\";\nimport CaptionXl from \"@govtnz/ds/build/vue-js/CaptionXl.vue\";\nimport H1 from \"@govtnz/ds/build/vue-js/H1.vue\";\nimport CaptionL from \"@govtnz/ds/build/vue-js/CaptionL.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport CaptionM from \"@govtnz/ds/build/vue-js/CaptionM.vue\";\nimport H3 from \"@govtnz/ds/build/vue-js/H3.vue\";\n\nexport default {\n  components: {\n    \"caption-xl\": CaptionXl,\n    \"c-h1\": H1,\n    \"caption-l\": CaptionL,\n    \"c-h2\": H2,\n    \"caption-m\": CaptionM,\n    \"c-h3\": H3\n  }\n};\n</script>\n",
    "vue-ts": "<template>\n  <caption-xl>\n    caption-xl\n  </caption-xl>\n  <c-h1 styleSize=\"xlarge\">\n    g-heading-xl\n  </c-h1>\n  <caption-l>\n    caption-l\n  </caption-l>\n  <c-h2 styleSize=\"large\">\n    g-heading-l\n  </c-h2>\n  <caption-m>\n    caption-m\n  </caption-m>\n  <c-h3 styleSize=\"medium\">\n    g-heading-m\n  </c-h3>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport CaptionXl from \"@govtnz/ds/build/vue-ts/CaptionXl.vue\";\nimport H1 from \"@govtnz/ds/build/vue-ts/H1.vue\";\nimport CaptionL from \"@govtnz/ds/build/vue-ts/CaptionL.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport CaptionM from \"@govtnz/ds/build/vue-ts/CaptionM.vue\";\nimport H3 from \"@govtnz/ds/build/vue-ts/H3.vue\";\n\nexport default {\n  components: {\n    \"caption-xl\": CaptionXl,\n    \"c-h1\": H1,\n    \"caption-l\": CaptionL,\n    \"c-h2\": H2,\n    \"caption-m\": CaptionM,\n    \"c-h3\": H3\n  }\n};\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: CaptionXl.css, H1.css, CaptionL.css, H2.css, CaptionM.css, H3.css\nOR in Sass (SCSS): CaptionXl.scss, H1.scss, CaptionL.scss, H2.scss, CaptionM.scss, H3.scss\n-->\n<span class=\"g-caption g-caption-xl\"> caption-xl </span>\n<h1 class=\"g-heading g-heading-xl\">g-heading-xl</h1>\n<span class=\"g-caption g-caption-l\"> caption-l </span>\n<h2 class=\"g-heading g-heading-l\">g-heading-l</h2>\n<span class=\"g-caption g-caption-m\"> caption-m </span>\n<h3 class=\"g-heading g-heading-m\">g-heading-m</h3>\n",
    "css": "",
    "twig-embed": "{% embed \"CaptionXl.html.twig\"  with {'children':'caption-xl'} only %}{% endembed %}{% embed \"H1.html.twig\"  with {'styleSize':'xlarge', 'children':'g-heading-xl'} only %}{% endembed %}{% embed \"CaptionL.html.twig\"  with {'children':'caption-l'} only %}{% endembed %}{% embed \"H2.html.twig\"  with {'styleSize':'large', 'children':'g-heading-l'} only %}{% endembed %}{% embed \"CaptionM.html.twig\"  with {'children':'caption-m'} only %}{% endembed %}{% embed \"H3.html.twig\"  with {'styleSize':'medium', 'children':'g-heading-m'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport H1 from \"@govtnz/ds/build/mustache/H1.mustache\";\nimport CaptionXl from \"@govtnz/ds/build/mustache/CaptionXl.mustache\";\n\n// The variables H1, CaptionXl are all strings that are mustache templates.\n\nexport default `${Mustache.render(H1, {\n  \"styleSize=xlarge\": true,\n  styleSize: true,\n  children: `${Mustache.render(CaptionXl, {\n    children: `caption-xl`\n  })}g-heading-xl`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-js/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport CaptionXl from \"@govtnz/ds/build/react-js/CaptionXl\";\nimport \"@govtnz/ds/build/css/CaptionXl.css\"; // or @govtnz/ds/build/scss/CaptionXl.scss\n\nexport default () => (\n  <H1 styleSize=\"xlarge\">\n    <CaptionXl>caption-xl</CaptionXl>\n    g-heading-xl\n  </H1>\n);\n",
    "react-ts": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-ts/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport CaptionXl from \"@govtnz/ds/build/react-ts/CaptionXl\";\nimport \"@govtnz/ds/build/css/CaptionXl.css\"; // or @govtnz/ds/build/scss/CaptionXl.scss\n\nexport default () => (\n  <H1 styleSize=\"xlarge\">\n    <CaptionXl>caption-xl</CaptionXl>\n    g-heading-xl\n  </H1>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-js-styled-components/H1\";\nimport CaptionXl from \"@govtnz/ds/build/react-js-styled-components/CaptionXl\";\n\nexport default () => (\n  <H1 styleSize=\"xlarge\">\n    <CaptionXl>caption-xl</CaptionXl>\n    g-heading-xl\n  </H1>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport H1 from \"@govtnz/ds/build/react-ts-styled-components/H1\";\nimport CaptionXl from \"@govtnz/ds/build/react-ts-styled-components/CaptionXl\";\n\nexport default () => (\n  <H1 styleSize=\"xlarge\">\n    <CaptionXl>caption-xl</CaptionXl>\n    g-heading-xl\n  </H1>\n);\n",
    "vue-js": "<template>\n  <c-h1 styleSize=\"xlarge\">\n    <caption-xl>\n      caption-xl\n    </caption-xl>\n    g-heading-xl\n  </c-h1>\n</template>\n<script>\nimport Vue from \"vue\";\nimport H1 from \"@govtnz/ds/build/vue-js/H1.vue\";\nimport CaptionXl from \"@govtnz/ds/build/vue-js/CaptionXl.vue\";\n\nexport default { components: { \"c-h1\": H1, \"caption-xl\": CaptionXl } };\n</script>\n",
    "vue-ts": "<template>\n  <c-h1 styleSize=\"xlarge\">\n    <caption-xl>\n      caption-xl\n    </caption-xl>\n    g-heading-xl\n  </c-h1>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport H1 from \"@govtnz/ds/build/vue-ts/H1.vue\";\nimport CaptionXl from \"@govtnz/ds/build/vue-ts/CaptionXl.vue\";\n\nexport default { components: { \"c-h1\": H1, \"caption-xl\": CaptionXl } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: H1.css, CaptionXl.css\nOR in Sass (SCSS): H1.scss, CaptionXl.scss\n-->\n<h1 class=\"g-heading g-heading-xl\">\n  <span class=\"g-caption g-caption-xl\"> caption-xl </span> g-heading-xl\n</h1>\n",
    "css": "",
    "twig-embed": "{% embed \"H1.html.twig\"  with {'styleSize':'xlarge'} only %}{% block children %}{% embed \"CaptionXl.html.twig\"  with {'children':'caption-xl'} only %}{% endembed %}\ng-heading-xl{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables P are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  \"styleSize=large\": true,\n  styleSize: true,\n  children: `Design and build fast, accessible websites backed by user research, so New Zealanders can easily use government services online.`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </P>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </P>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <P styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </P>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <P styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </P>\n);\n",
    "vue-js": "<template>\n  <c-p styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </c-p>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p styleSize=\"large\">\n    Design and build fast, accessible websites backed by user research, so New\n    Zealanders can easily use government services online.\n  </c-p>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css\nOR in Sass (SCSS): P.scss\n-->\n<p class=\"g-body g-body-l\">\n  Design and build fast, accessible websites backed by user research, so New\n  Zealanders can easily use government services online.\n</p>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\"  with {'styleSize':'large', 'children':'Design and build fast, accessible websites backed by user research, so New Zealanders can easily use government services online.'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables P are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  children: `The New Zealand Government Design System (NZGDS) is a catalogue of reusable basic elements, components and patterns for building websites and applications, along with guidance on how and when to use them.`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </P>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </P>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <P>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </P>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <P>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </P>\n);\n",
    "vue-js": "<template>\n  <c-p>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </c-p>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p>\n    The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n    basic elements, components and patterns for building websites and\n    applications, along with guidance on how and when to use them.\n  </c-p>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css\nOR in Sass (SCSS): P.scss\n-->\n<p class=\"g-body\">\n  The New Zealand Government Design System (NZGDS) is a catalogue of reusable\n  basic elements, components and patterns for building websites and\n  applications, along with guidance on how and when to use them.\n</p>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\"  with {'children':'The New Zealand Government Design System (NZGDS) is a catalogue of reusable basic elements, components and patterns for building websites and applications, along with guidance on how and when to use them.'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables P are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  \"styleSize=small\": true,\n  styleSize: true,\n  children: `Government Information Services is creating the design system now, and you can contribute. This alpha site shows you what we have built so far, what we are working on, and how you can get involved.`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </P>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <P styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </P>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <P styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </P>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <P styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </P>\n);\n",
    "vue-js": "<template>\n  <c-p styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </c-p>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p styleSize=\"small\">\n    Government Information Services is creating the design system now, and you\n    can contribute. This alpha site shows you what we have built so far, what we\n    are working on, and how you can get involved.\n  </c-p>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css\nOR in Sass (SCSS): P.scss\n-->\n<p class=\"g-body g-body-s\">\n  Government Information Services is creating the design system now, and you can\n  contribute. This alpha site shows you what we have built so far, what we are\n  working on, and how you can get involved.\n</p>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\"  with {'styleSize':'small', 'children':'Government Information Services is creating the design system now, and you can contribute. This alpha site shows you what we have built so far, what we are working on, and how you can get involved.'} only %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\nimport A from \"@govtnz/ds/build/mustache/A.mustache\";\n\n// The variables P, A are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  children: `${Mustache.render(A, {\n    href: `#`,\n    children: `Discuss typography on 'GitHub issues'`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport A from \"@govtnz/ds/build/react-js/A\";\nimport \"@govtnz/ds/build/css/A.css\"; // or @govtnz/ds/build/scss/A.scss\n\nexport default () => (\n  <P>\n    <A href=\"#\">Discuss typography on 'GitHub issues'</A>\n  </P>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport A from \"@govtnz/ds/build/react-ts/A\";\nimport \"@govtnz/ds/build/css/A.css\"; // or @govtnz/ds/build/scss/A.scss\n\nexport default () => (\n  <P>\n    <A href=\"#\">Discuss typography on 'GitHub issues'</A>\n  </P>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\nimport A from \"@govtnz/ds/build/react-js-styled-components/A\";\n\nexport default () => (\n  <P>\n    <A href=\"#\">Discuss typography on 'GitHub issues'</A>\n  </P>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\nimport A from \"@govtnz/ds/build/react-ts-styled-components/A\";\n\nexport default () => (\n  <P>\n    <A href=\"#\">Discuss typography on 'GitHub issues'</A>\n  </P>\n);\n",
    "vue-js": "<template>\n  <c-p>\n    <c-a href=\"#\">\n      Discuss typography on 'GitHub issues'\n    </c-a>\n  </c-p>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\nimport A from \"@govtnz/ds/build/vue-js/A.vue\";\n\nexport default { components: { \"c-p\": P, \"c-a\": A } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p>\n    <c-a href=\"#\">\n      Discuss typography on 'GitHub issues'\n    </c-a>\n  </c-p>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\nimport A from \"@govtnz/ds/build/vue-ts/A.vue\";\n\nexport default { components: { \"c-p\": P, \"c-a\": A } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css, A.css\nOR in Sass (SCSS): P.scss, A.scss\n-->\n<p class=\"g-body\">\n  <a class=\"g-link\" href=\"#\"> Discuss typography on 'GitHub issues' </a>\n</p>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\" %}{% block children %}{% embed \"A.html.twig\"  with {'href':'#', 'children':'Discuss typography on 'GitHub issues''} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\nimport Ul from \"@govtnz/ds/build/mustache/Ul.mustache\";\nimport Li from \"@govtnz/ds/build/mustache/Li.mustache\";\n\n// The variables P, Ul, Li are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  children: `To help keep the kitchen clean, please:`\n})}${Mustache.render(Ul, {\n  children: `${Mustache.render(Li, {\n    children: `replace tea and coffee jars on shelf`\n  })}${Mustache.render(Li, {\n    children: `wipe down bench`\n  })}${Mustache.render(Li, {\n    children: `wash dishes by:${Mustache.render(Ul, {\n      children: `${Mustache.render(Li, {\n        children: `loading the dishwasher, if it's empty`\n      })}${Mustache.render(Li, {\n        children: `rinsing and stacking, if dishwasher is going`\n      })}`\n    })}`\n  })}${Mustache.render(Li, { children: `put recycling in labelled bins` })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport Ul from \"@govtnz/ds/build/react-js/Ul\";\nimport \"@govtnz/ds/build/css/Ul.css\"; // or @govtnz/ds/build/scss/Ul.scss\nimport Li from \"@govtnz/ds/build/react-js/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\n\nexport default () => (\n  <React.Fragment>\n    <P>To help keep the kitchen clean, please:</P>\n    <Ul>\n      <Li>replace tea and coffee jars on shelf</Li>\n      <Li>wipe down bench</Li>\n      <Li>\n        wash dishes by:\n        <Ul>\n          <Li>loading the dishwasher, if it's empty</Li>\n          <Li>rinsing and stacking, if dishwasher is going</Li>\n        </Ul>\n      </Li>\n      <Li>put recycling in labelled bins</Li>\n    </Ul>\n  </React.Fragment>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport Ul from \"@govtnz/ds/build/react-ts/Ul\";\nimport \"@govtnz/ds/build/css/Ul.css\"; // or @govtnz/ds/build/scss/Ul.scss\nimport Li from \"@govtnz/ds/build/react-ts/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\n\nexport default () => (\n  <React.Fragment>\n    <P>To help keep the kitchen clean, please:</P>\n    <Ul>\n      <Li>replace tea and coffee jars on shelf</Li>\n      <Li>wipe down bench</Li>\n      <Li>\n        wash dishes by:\n        <Ul>\n          <Li>loading the dishwasher, if it's empty</Li>\n          <Li>rinsing and stacking, if dishwasher is going</Li>\n        </Ul>\n      </Li>\n      <Li>put recycling in labelled bins</Li>\n    </Ul>\n  </React.Fragment>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\nimport Ul from \"@govtnz/ds/build/react-js-styled-components/Ul\";\nimport Li from \"@govtnz/ds/build/react-js-styled-components/Li\";\n\nexport default () => (\n  <React.Fragment>\n    <P>To help keep the kitchen clean, please:</P>\n    <Ul>\n      <Li>replace tea and coffee jars on shelf</Li>\n      <Li>wipe down bench</Li>\n      <Li>\n        wash dishes by:\n        <Ul>\n          <Li>loading the dishwasher, if it's empty</Li>\n          <Li>rinsing and stacking, if dishwasher is going</Li>\n        </Ul>\n      </Li>\n      <Li>put recycling in labelled bins</Li>\n    </Ul>\n  </React.Fragment>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\nimport Ul from \"@govtnz/ds/build/react-ts-styled-components/Ul\";\nimport Li from \"@govtnz/ds/build/react-ts-styled-components/Li\";\n\nexport default () => (\n  <React.Fragment>\n    <P>To help keep the kitchen clean, please:</P>\n    <Ul>\n      <Li>replace tea and coffee jars on shelf</Li>\n      <Li>wipe down bench</Li>\n      <Li>\n        wash dishes by:\n        <Ul>\n          <Li>loading the dishwasher, if it's empty</Li>\n          <Li>rinsing and stacking, if dishwasher is going</Li>\n        </Ul>\n      </Li>\n      <Li>put recycling in labelled bins</Li>\n    </Ul>\n  </React.Fragment>\n);\n",
    "vue-js": "<template>\n  <c-p>\n    To help keep the kitchen clean, please:\n  </c-p>\n  <c-ul>\n    <c-li>\n      replace tea and coffee jars on shelf\n    </c-li>\n    <c-li>\n      wipe down bench\n    </c-li>\n    <c-li>\n      wash dishes by:<c-ul>\n        <c-li>\n          loading the dishwasher, if it's empty\n        </c-li>\n        <c-li>\n          rinsing and stacking, if dishwasher is going\n        </c-li>\n      </c-ul>\n    </c-li>\n    <c-li>\n      put recycling in labelled bins\n    </c-li>\n  </c-ul>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\nimport Ul from \"@govtnz/ds/build/vue-js/Ul.vue\";\nimport Li from \"@govtnz/ds/build/vue-js/Li.vue\";\n\nexport default { components: { \"c-p\": P, \"c-ul\": Ul, \"c-li\": Li } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p>\n    To help keep the kitchen clean, please:\n  </c-p>\n  <c-ul>\n    <c-li>\n      replace tea and coffee jars on shelf\n    </c-li>\n    <c-li>\n      wipe down bench\n    </c-li>\n    <c-li>\n      wash dishes by:<c-ul>\n        <c-li>\n          loading the dishwasher, if it's empty\n        </c-li>\n        <c-li>\n          rinsing and stacking, if dishwasher is going\n        </c-li>\n      </c-ul>\n    </c-li>\n    <c-li>\n      put recycling in labelled bins\n    </c-li>\n  </c-ul>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\nimport Ul from \"@govtnz/ds/build/vue-ts/Ul.vue\";\nimport Li from \"@govtnz/ds/build/vue-ts/Li.vue\";\n\nexport default { components: { \"c-p\": P, \"c-ul\": Ul, \"c-li\": Li } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css, Ul.css, Li.css\nOR in Sass (SCSS): P.scss, Ul.scss, Li.scss\n-->\n<p class=\"g-body\">To help keep the kitchen clean, please:</p>\n<ul class=\"g-ul\">\n  <li>replace tea and coffee jars on shelf</li>\n  <li>wipe down bench</li>\n  <li>\n    wash dishes by:\n    <ul class=\"g-ul\">\n      <li>loading the dishwasher, if it's empty</li>\n      <li>rinsing and stacking, if dishwasher is going</li>\n    </ul>\n  </li>\n  <li>put recycling in labelled bins</li>\n</ul>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\"  with {'children':'To help keep the kitchen clean, please:'} only %}{% endembed %}{% embed \"Ul.html.twig\" %}{% block children %}{% embed \"Li.html.twig\"  with {'children':'replace tea and coffee jars on shelf'} only %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'wipe down bench'} only %}{% endembed %}\n{% embed \"Li.html.twig\" %}{% block children %}wash dishes by:\n{% embed \"Ul.html.twig\" %}{% block children %}{% embed \"Li.html.twig\"  with {'children':'loading the dishwasher, if it's empty'} only %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'rinsing and stacking, if dishwasher is going'} only %}{% endembed %}{% endblock %}{% endembed %}{% endblock %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'put recycling in labelled bins'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\nimport Ol from \"@govtnz/ds/build/mustache/Ol.mustache\";\nimport Li from \"@govtnz/ds/build/mustache/Li.mustache\";\n\n// The variables P, Ol, Li are all strings that are mustache templates.\n\nexport default `${Mustache.render(P, {\n  children: `To sign up, you need to complete four steps.`\n})}${Mustache.render(Ol, {\n  children: `${Mustache.render(Li, {\n    children: `Prove your identity, such as with RealMe`\n  })}${Mustache.render(Li, {\n    children: `Provide a passport-sized photo`\n  })}${Mustache.render(Li, {\n    children: `Enter your address details:${Mustache.render(Ol, {\n      children: `${Mustache.render(Li, {\n        children: `Postal address`\n      })}${Mustache.render(Li, { children: `Delivery address` })}`\n    })}`\n  })}${Mustache.render(Li, { children: `Confirm payment methods` })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport Ol from \"@govtnz/ds/build/react-js/Ol\";\nimport \"@govtnz/ds/build/css/Ol.css\"; // or @govtnz/ds/build/scss/Ol.scss\nimport Li from \"@govtnz/ds/build/react-js/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\n\nexport default () => (\n  <React.Fragment>\n    <P>To sign up, you need to complete four steps.</P>\n    <Ol>\n      <Li>Prove your identity, such as with RealMe</Li>\n      <Li>Provide a passport-sized photo</Li>\n      <Li>\n        Enter your address details:\n        <Ol>\n          <Li>Postal address</Li>\n          <Li>Delivery address</Li>\n        </Ol>\n      </Li>\n      <Li>Confirm payment methods</Li>\n    </Ol>\n  </React.Fragment>\n);\n",
    "react-ts": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\nimport Ol from \"@govtnz/ds/build/react-ts/Ol\";\nimport \"@govtnz/ds/build/css/Ol.css\"; // or @govtnz/ds/build/scss/Ol.scss\nimport Li from \"@govtnz/ds/build/react-ts/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\n\nexport default () => (\n  <React.Fragment>\n    <P>To sign up, you need to complete four steps.</P>\n    <Ol>\n      <Li>Prove your identity, such as with RealMe</Li>\n      <Li>Provide a passport-sized photo</Li>\n      <Li>\n        Enter your address details:\n        <Ol>\n          <Li>Postal address</Li>\n          <Li>Delivery address</Li>\n        </Ol>\n      </Li>\n      <Li>Confirm payment methods</Li>\n    </Ol>\n  </React.Fragment>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\nimport Ol from \"@govtnz/ds/build/react-js-styled-components/Ol\";\nimport Li from \"@govtnz/ds/build/react-js-styled-components/Li\";\n\nexport default () => (\n  <React.Fragment>\n    <P>To sign up, you need to complete four steps.</P>\n    <Ol>\n      <Li>Prove your identity, such as with RealMe</Li>\n      <Li>Provide a passport-sized photo</Li>\n      <Li>\n        Enter your address details:\n        <Ol>\n          <Li>Postal address</Li>\n          <Li>Delivery address</Li>\n        </Ol>\n      </Li>\n      <Li>Confirm payment methods</Li>\n    </Ol>\n  </React.Fragment>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\nimport Ol from \"@govtnz/ds/build/react-ts-styled-components/Ol\";\nimport Li from \"@govtnz/ds/build/react-ts-styled-components/Li\";\n\nexport default () => (\n  <React.Fragment>\n    <P>To sign up, you need to complete four steps.</P>\n    <Ol>\n      <Li>Prove your identity, such as with RealMe</Li>\n      <Li>Provide a passport-sized photo</Li>\n      <Li>\n        Enter your address details:\n        <Ol>\n          <Li>Postal address</Li>\n          <Li>Delivery address</Li>\n        </Ol>\n      </Li>\n      <Li>Confirm payment methods</Li>\n    </Ol>\n  </React.Fragment>\n);\n",
    "vue-js": "<template>\n  <c-p>\n    To sign up, you need to complete four steps.\n  </c-p>\n  <c-ol>\n    <c-li>\n      Prove your identity, such as with RealMe\n    </c-li>\n    <c-li>\n      Provide a passport-sized photo\n    </c-li>\n    <c-li>\n      Enter your address details:<c-ol>\n        <c-li>\n          Postal address\n        </c-li>\n        <c-li>\n          Delivery address\n        </c-li>\n      </c-ol>\n    </c-li>\n    <c-li>\n      Confirm payment methods\n    </c-li>\n  </c-ol>\n</template>\n<script>\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\nimport Ol from \"@govtnz/ds/build/vue-js/Ol.vue\";\nimport Li from \"@govtnz/ds/build/vue-js/Li.vue\";\n\nexport default { components: { \"c-p\": P, \"c-ol\": Ol, \"c-li\": Li } };\n</script>\n",
    "vue-ts": "<template>\n  <c-p>\n    To sign up, you need to complete four steps.\n  </c-p>\n  <c-ol>\n    <c-li>\n      Prove your identity, such as with RealMe\n    </c-li>\n    <c-li>\n      Provide a passport-sized photo\n    </c-li>\n    <c-li>\n      Enter your address details:<c-ol>\n        <c-li>\n          Postal address\n        </c-li>\n        <c-li>\n          Delivery address\n        </c-li>\n      </c-ol>\n    </c-li>\n    <c-li>\n      Confirm payment methods\n    </c-li>\n  </c-ol>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\nimport Ol from \"@govtnz/ds/build/vue-ts/Ol.vue\";\nimport Li from \"@govtnz/ds/build/vue-ts/Li.vue\";\n\nexport default { components: { \"c-p\": P, \"c-ol\": Ol, \"c-li\": Li } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: P.css, Ol.css, Li.css\nOR in Sass (SCSS): P.scss, Ol.scss, Li.scss\n-->\n<p class=\"g-body\">To sign up, you need to complete four steps.</p>\n<ol class=\"g-ol\">\n  <li>Prove your identity, such as with RealMe</li>\n  <li>Provide a passport-sized photo</li>\n  <li>\n    Enter your address details:\n    <ol class=\"g-ol\">\n      <li>Postal address</li>\n      <li>Delivery address</li>\n    </ol>\n  </li>\n  <li>Confirm payment methods</li>\n</ol>\n",
    "css": "",
    "twig-embed": "{% embed \"P.html.twig\"  with {'children':'To sign up, you need to complete four steps.'} only %}{% endembed %}{% embed \"Ol.html.twig\" %}{% block children %}{% embed \"Li.html.twig\"  with {'children':'Prove your identity, such as with RealMe'} only %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'Provide a passport-sized photo'} only %}{% endembed %}\n{% embed \"Li.html.twig\" %}{% block children %}Enter your address details:\n{% embed \"Ol.html.twig\" %}{% block children %}{% embed \"Li.html.twig\"  with {'children':'Postal address'} only %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'Delivery address'} only %}{% endembed %}{% endblock %}{% endembed %}{% endblock %}{% endembed %}\n{% embed \"Li.html.twig\"  with {'children':'Confirm payment methods'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  }
]